import React from 'react';
import { Form, Input, Button, Checkbox } from 'antd';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import './Login.css'

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 100,
    },
};
const tailLayout = {
    wrapperCol: {
        span: 100,
    },
};

export default function Login(props) {
    let loading = false
    const onFinish = values => {
        console.log('Success:', values);
        props.getValues(values)
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Form id="loginForm"
            {...layout}
            name="basic"
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
        >
            <Form.Item
                name="email"
                rules={[
                    {
                        required: true,
                        message: 'يرجى ادخال البريد الالكتروني',
                    },
                    {
                        type: 'email',
                        message: 'البريد الالكتروني غير صحيح'
                    }
                ]}
            >
                <Input placeholder="البريد الالكتروني" suffix={<MailOutlined style={{ fontSize: '19px', color: '#0A154D', marginTop: '10px' }} />} />
            </Form.Item>

            <Form.Item
                name="password"
                rules={[
                    {
                        required: true,
                        message: ' يرجى ادخال كلمة المرور',
                    },
                ]}
            >
                <Input type="password" placeholder="كلمه المرور" suffix={<LockOutlined style={{ fontSize: '19px', color: '#0A154D', marginTop: '10px' }} />} />
            </Form.Item>

            <Form.Item {...tailLayout} >
                <Button type="primary" htmlType="submit" loading={props.loading}>
                دخول
        </Button>
            </Form.Item>
        </Form>
    );
};
