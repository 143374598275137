import { IntializeToken, sendLogout, GetUser } from '../../Redux/Action/LoginAction.js';
import { Redirect, Route, Switch, BrowserRouter } from "react-router-dom";
import birthDatePhoto from "../../Assets/Images/birthdate.gif"
import EmplyeePhoto from "../../Assets/Images/employee.jpg"
import { init } from '../../Redux/Action/SocketAction';
import { API_ENDPOINT, API_SOCKET_ENDPOINT } from '../../AppConfig.js'
import { Layout, Menu, Badge } from 'antd';
import { bindActionCreators } from 'redux';
import Routes from "./SideMenuRoutes.js"
import { Link } from "react-router-dom"
import { connect } from 'react-redux';
import routes from "../../routes.js"
import cookie from 'react-cookies'
import moment from "moment";
import 'antd/dist/antd.css';
import React from 'react';
import './SideMenu.css';

class DefaultLayout extends React.Component {
  state = {
    activeRoute: 0,
    redirect: true,
    collapsed: false,
  };

  onCollapse = collapsed => {
    this.setState({ collapsed });
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  componentWillMount() {
    this.activeRoute()

    if (cookie.load("ElmoaserToken")) {
      this.props.IntializeToken(cookie.load("ElmoaserToken"), cookie.load("ElmoaserLang"),
        JSON.parse(localStorage.getItem("ElmoaserUser")))
      this.props.init(JSON.parse(localStorage.getItem("ElmoaserUser")).id)
      this.props.GetUser(JSON.parse(localStorage.getItem("ElmoaserUser")).email)
    }

    let activeRoute = localStorage.getItem("activeRoute");
    this.setState({ activeRoute: activeRoute })
  }

  activeRoute() {
    if (localStorage.getItem("activeRoute") === null) {
      localStorage.setItem("activeRoute", "0")
    }
    else {
      this.setState({ activeRoute: localStorage.getItem("activeRoute") })
    }
  }

  settingActiveRoute = (index) => {
    this.setState({ activeRoute: index })
    localStorage.setItem("activeRoute", index)
  }

  SaveRoute(url) {
    localStorage.setItem("lastURL", url)
  }

  render() {
    const { Content, Sider } = Layout;

    if (this.state.redirect) {
      this.setState({ redirect: false })
    }

    let user = this.props.user;
    let token = cookie.load('ElmoaserToken');
    let menuItem = []


    if (user) {
      for (let route of Routes) {
        if (!route.url || (route.url && user.permissions.includes(route.url))) {
          menuItem.push(route)
        }
      }
    }

    // let checkBirthDate = ((user && user.birthDate) && moment(new Date(user.birthDate)).isBetween(moment(new Date()).startOf('day'), moment(new Date()).endOf('day'))) ? `url(${birthDatePhoto})` : 'none'
    let checkBirthDate = ((user && user.birthDate) && moment(new Date(user.birthDate)).format('MM-DD') == moment(new Date()).format('MM-DD')) ? `url(${birthDatePhoto})` : 'none'
    return (
      token ?
        <BrowserRouter>
          <Sider data-aos="fade-up" data-aos-delay="350" breakpoint="sm" collapsedWidth="80" style={{ height: '100vh', position: 'fixed', right: 0, zIndex: 3 }}>
            <div className="logo" style={{ backgroundImage: checkBirthDate, borderRadius: '20px', color: checkBirthDate != 'none' ? '#fff' : '#000' }}>
              {/* user ? API_ENDPOINT+user.image :  */}
              <img src={user ? user.image ? API_SOCKET_ENDPOINT + user.image : EmplyeePhoto : EmplyeePhoto} className="img-fluid userPhoto" alt="user photo" title="the name of the employee" />
              <p className="EmployeeName" style={{ color: checkBirthDate != 'none' ? '#fff' : '#0A154D' }}> {user && user.name} </p>
            </div>
            <Menu theme="light" defaultSelectedKeys={[`${this.state.activeRoute}`]} mode="inline">
              {menuItem.map((element, index) => {
                return (
                  <Menu.Item key={index}>
                    <div className="MenuItem" onClick={this.settingActiveRoute.bind(this, index)}>
                      {element.url ?
                        <Link to={element.url}>
                          <div style={{ width: '100%' }} onClick={() => this.SaveRoute(element.url)}>
                            <i className={element.icon}></i>
                            <span>{element.title}</span>
                            {(element.badge && this.props.count > 0) &&
                              <Badge className="site-badge-count-109" count={this.props.count} />}
                          </div></Link> :
                        <div>
                          <i className={element.icon} style={{ color: '#DD1C23' }}></i>
                          <span onClick={() => this.props.sendLogout()} style={{ color: '#DD1C23' }}>{element.title}</span>
                        </div>
                      }
                    </div>
                  </Menu.Item>
                )
              })}
            </Menu>
          </Sider>
          <Layout data-aos="fade-up" data-aos-delay="350" className="site-layout">
            <Content className="contentContainer" style={{ minHeight: window.innerHeight }}>
              <div className="site-layout-background">
                <Switch>
                  {routes.map((route, idx) => {
                    return route.component ? (
                      <Route
                        key={idx}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        render={props => <route.component {...props} />}
                      />
                    ) : null;
                  })}
                  {this.state.redirect && <Redirect from="/:value" to={localStorage.getItem("lastURL") ? localStorage.getItem("lastURL") : '/PersonalInformation'} />}
                </Switch>
              </div>
            </Content>
          </Layout>
        </BrowserRouter> : <Redirect to="/login" />
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.userInfo.user,
    count: state.socket.MessageCount,
    newMessage: state.socket.NewMessage,
  }
}

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    IntializeToken, sendLogout, init, GetUser
  }
  , dispatch
)

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);