
import {
  FETCH_LOGIN_REQUEST,
  LOGIN,
  SUBMIT,
  LOGIN_FAILED,
  INTIALIZE_TOKEN,
  REMOVE_TOKEN,
  REFRESH_USER
} from "./type";
import axios from 'axios';
import { API_ENDPOINT } from '../../AppConfig';
import { showSnack } from 'react-redux-snackbar';
import cookie from 'react-cookies'


export function loginRequest(data) {
  let Lang2 = cookie.load("ElmoaserLang", false);

  return (dispatch, getState) => {
    dispatch({ type: SUBMIT });
    dispatch({ type: FETCH_LOGIN_REQUEST });
    axios.post(`${API_ENDPOINT}/signin`, data, {
      headers: {
        "Accept-Language": "en",
      }
    }
    )
      .then(response => {
        cookie.save("logged", true);
        cookie.save('ElmoaserUser', response.data.user, { path: '/' });
        cookie.save('ElmoaserToken', response.data.token, { path: '/' });
        cookie.save('ElmoaserType', response.data.user.type, { path: '/' });
        cookie.save('animation', true, { path: '/' });
        //  cookie.save('ElmoaserLang',  JSON.stringify(response.data.user.language),{path: '/Elmoaser/'})

        localStorage.setItem('logged', true);
        localStorage.setItem('time', "");
        localStorage.setItem('ElmoaserUser', JSON.stringify(response.data.user));
        // localStorage.setItem('ElmoaserToken', JSON.stringify(response.data.token));
        //  localStorage.setItem('ElmoaserLang', JSON.stringify(response.data.user.language));

        dispatch({ type: SUBMIT });
        dispatch({ payload: response.data, type: LOGIN });
      })
      .catch(err => {
        dispatch({ type: SUBMIT });
        dispatch({ type: LOGIN_FAILED });
        if (!err.response) {
          dispatch(showSnack('myUniqueId', {
            label: Lang2 === 'en' ? 'Network Error' : 'خطأ فى الاتصال بالانترنت',
            timeout: 7000,
            button: "أغلق"
          }));

        }
        else if (err.response.status === 404 || err.response.status === 422) {
          dispatch(showSnack('myUniqueId', {
            label: Lang2 === "en" ? 'Password or email is not correct' : 'البريد الالكترونى او كلمة المرور غير صحيحة',
            timeout: 7000,
            button: { label: Lang2 === "en" ? 'LogIn' : ' تسجيل الدخول' }
          }));
        }
        else if (err.response.status === 400) {
          dispatch(showSnack('myUniqueId', {
            label: err.response.data.errors[0].msg,
            timeout: 7000,
            button: "أغلق"
          }));


        }
        else if (err.response.status === 403) {
          dispatch(showSnack('myUniqueId', {
            label: Lang2 === 'en' ? 'forbidden user ' : "هذا الحساب غير موجود",
            timeout: 7000,
            button: "أغلق"
          }));


        }

      })

  }
}

export function GetUser(email) {
  return (dispatch, getState) => {
    let url = `${API_ENDPOINT}/allUsers?email=${email}`
    axios.get(url)
      .then(response => {
        dispatch({ type: REFRESH_USER, payload: response.data.data[0] });
        localStorage.setItem('ElmoaserUser', JSON.stringify(response.data.data[0]));
      })

  }
}

export function sendLogout() {
  cookie.remove('ElmoaserUser', { path: '/' });
  cookie.remove('ElmoaserToken', { path: '/' });
  cookie.remove('ElmoaserType', { path: '/' });
  cookie.remove('logged', { path: '/' });

  return (dispatch, getState) => {
    dispatch({ type: REMOVE_TOKEN });
    dispatch({ type: SUBMIT });
  }
}

export function IntializeToken(token, lang, user) {
  return (dispatch, getState) => {
    dispatch({ type: INTIALIZE_TOKEN, payload: { token: token, user: user } });
  }
}

export function saveToLocalStorage(key, value) {
  localStorage.setItem(key, value);
}
