import React, { Component } from 'react';
import { Route, BrowserRouter, Switch, Redirect } from 'react-router-dom';
import DefaultLayout from "./Components/DefaultLayout/DefaultLayout"
import cookie from 'react-cookies'
import AOS from 'aos';
import 'aos/dist/aos.css';
import './App.css';
import LogIn from "./Views/Auth/Login.js"

class App extends Component {

  componentDidMount() {
    AOS.init({
      once: true,
    });
  }

  render() {
    let token = cookie.load('ElmoaserToken') ? cookie.load('ElmoaserToken') : null;
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/login" component={LogIn} />
          <Route exact path="/Elmoaser/:value" component={DefaultLayout} />
        </Switch>
        {!token &&  <Redirect to="/login" />}
      </BrowserRouter>
    );
  }
}

export default App;