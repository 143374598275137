import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import "@fortawesome/fontawesome-free/css/all.min.css";

import { Provider } from "react-redux";
import { Snackbar } from 'react-redux-snackbar';
import Store from "./Redux/Store";

ReactDOM.render(
<Provider store={Store}>
        <div>
            <App />
            <Snackbar />
        </div>
    </Provider>, document.getElementById('root'));
serviceWorker.unregister();
