

const initialState ={
  email:'',
  page: 'Email'
}


const ForgetReducer =(state=initialState,action)=>{
  switch(action.type){
    case 'FORGET_PASSWORD':
      console.log("hello from switch case: ",action.payload);
      return {...state, email: action.payload, page: "ConfirmCode"};

    case 'CONFIRMATION_CODE':

      return {...state,page:"newPasswordPage"}

    case 'NEW_PASSWORD':

    console.log("last page ");
      return{...state, page:"Dashboard"}
      
    default:
      return state;
  }

}

export default ForgetReducer;









/////////////////////////////////////////////////


// import * as types from "../Action/type";

// const initialState = {
//     loading: false,
//     page: 'email',
//     error: null,
// }
// const AuthReducer = (state = initialState, action) => {
//     switch (action.type) {
//         case types.FETCH_LOGIN_REQUEST:
//             return { ...state, loading: true, error: null };
//         case types.FETCH_FORGET_SUCCESS:
//             return {
//                 ...state,
//                 page: action.payload,
//                 loading: false,
//             };
        
//         default:
//             return state;
//     }

// }
// export default AuthReducer;