import * as types from "../Action/type";
import cookie from "react-cookies";

const initialState = {
    loading: false,
    // lang:localStorage.getItem('Language'),
    lang: cookie.load('ElmoaserLang'),
    status:true,
}

// console.log("bbbbbbbbbbbbbbbbbbbbbbbbbbbbb : ",localStorage.getItem('Language'));

const languageReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_Lang_REQUEST:
                // console.log("Language  reducer:",action.payload)
            return {
                ...state,
                lang: action.payload,
                loading: false,
            };   
            
        default:
            return state;
    }
}

export default languageReducer;