import React, { Component } from 'react';
import { loginRequest } from '../../Redux/Action/LoginAction';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from "react-router-dom";
import Login from './LoginForm.js'
import Logo from '../../Assets/Images/Group 474.png'
import './Login.css'
import { sendLogout } from '../../Redux/Action/LoginAction';
import cookie from 'react-cookies'


class LoginForm extends Component {

    constructor() {
        super();
        this.state = {

        };
    }

    getValues = (values) => {
        values.type = "EMPLOYEE"
        this.props.loginRequest(values);
    }
    componentWillMount() {
        this.props.sendLogout()
        localStorage.setItem("activeRoute", "0")
    }

    render() {
        if (this.props.token) {
            return (
                <Redirect to='/Elmoaser/PersonalInformation' />
            )
        }
        let token = cookie.load('animation');
        console.log("ttttttttt", token)
        return (
            token ?
                <div style={{ minHeight: window.innerHeight, background: '#F1F3F9' }}>

                    <div className="Style2_MainContentLogin" >
                        <div className="style2_overlay">

                            <div className="style2_loginForm py-0 m-0 " id="style2_loginForm" >

                                <div style={{ alignSelf: 'center' }} >

                                    <div id="logoo">
                                        <div className="LogoDiv">

                                        </div>
                                    </div>

                                    <Login getValues={this.getValues} loading={this.props.loading} />

                                </div>

                            </div>

                        </div>
                    </div>
                </div> :
                <div style={{ minHeight: window.innerHeight, background: '#F1F3F9' }}>

                    <div data-aos="fade-down" className="firstlogo" data-aos-delay="350"> <img src={Logo} alt="logo" /> </div>
                    <div className="Style2_MainContentLogin" data-aos="zoom-in" data-aos-delay="850" data-aos-duration="1500">
                        <div className="style2_overlay">

                            <div className="style2_loginForm py-0 m-0 " id="style2_loginForm" data-aos="zoom-in-down" data-aos-delay="2150" data-aos-duration="1000">

                                <div style={{ alignSelf: 'center' }} >

                                    <div id="logoo">
                                        <div className="LogoDiv">

                                        </div>
                                    </div>

                                    <Login getValues={this.getValues} loading={this.props.loading} />

                                </div>

                            </div>

                        </div>
                    </div>
                </div>
        );
    }
}


const mapStateToProps = state => {

    return {
        token: state.userInfo.token,
        submit: state.userInfo.submit,
        lang: state.userInfo.language,
        loading: state.userInfo.loading,
    }
}

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        loginRequest,
        sendLogout

    }
    , dispatch
)
export default (connect(mapStateToProps, mapDispatchToProps)(LoginForm));