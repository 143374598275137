import { combineReducers } from "redux";
import { reducer as formReducer } from 'redux-form';
import { snackbarReducer } from 'react-redux-snackbar';
import Login from './LoginReducer'
import Forget from './ForgetReducer'
import Language from './LanguageReducer'
import loginReducer from './LoginReducer';
import ForgetReducer from './ForgetReducer';
import SocketReducer from "./SocketReducer"


export default combineReducers({
   form: formReducer,
   // login: Login,
   // forget: Forget,
   snackbar: snackbarReducer,
   socket:SocketReducer,
   userInfo: Login,
   forgetPass: ForgetReducer,
   lang: Language,
   
});
