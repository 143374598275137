
import * as types from "../Action/type"
import cookie from "react-cookies";

let temp = cookie.load("ElmoaserLang");
const initialState = {
  user: null,
  token: '',
  submit: false,
  // language:localStorage.getItem('GuideLang')!==null?localStorage.getItem('GuideLang'):"ar",
  language: temp ? JSON.parse(temp) : "ar",
  loading: false
}


const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_LOGIN_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.LOGIN_FAILED:
      return {
        ...state,
        loading: false
      }
    case types.LOGIN:
      return {
        ...state, user: action.payload.user, token: action.payload.token, language: action.payload.user.language, Type: action.payload.user.type,
        Rules: action.payload.user.rules, submit: !state.submit, loading: false
      };
    case types.REFRESH_USER:
      return {
        ...state, user: action.payload
      }
    case types.SUBMIT:
      return { ...state, submit: !state.submit }
    case types.FETCH_Lang_REQUEST:
      return { ...state, language: action.payload, };
    case types.REMOVE_TOKEN:
      return { ...state, user: null, token: null, submit: true };
    case types.INTIALIZE_TOKEN:
      return { ...state, user: action.payload.user, token: action.payload.token };
    default:
      return state;
  }


}
export default UserReducer;
