// Login
export const FETCH_LOGIN_REQUEST = "FETCH_LOGIN_REQUEST"
export const FETCH_LOGIN_SUCCESS = "FETCH_LOGIN_SUCCESS"
export const INTIALIZE_TOKEN = "INTIALIZE_TOKEN"
export const REMOVE_TOKEN = "REMOVE_TOKEN"
export const FETCH_FORGET_SUCCESS = "FETCH_FORGET_SUCCESS"
export const FETCH_Lang_REQUEST = " FETCH_Lang_REQUEST"
export const FEACH_UPDATE_STATE = "FEACH_UPDATE_STATE"
export const FEACH_NEW_ORDER = "FEACH_NEW_ORDER"
export const LOGIN_FAILED = "LOGIN_FAILED"
export const REFRESH_USER = "REFRESH_USER"


export const LOGIN = "LOGIN";
export const SUBMIT = "SUBMIT";
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const CONFIRMATION_CODE = "CONFIRMATION_CODE";
export const NEW_PASSWORD = "NEW_PASSWORD";
export const FEACH_UPDATE_COUNT = "FEACH_UPDATE_COUNT"
export const FEACH_NEW_MESSAGE = "FEACH_NEW_MESSAGE"

