import io from "socket.io-client";
import {
    FEACH_UPDATE_COUNT, FEACH_NEW_MESSAGE,
} from "./type";
import { API_SOCKET_ENDPOINT } from '../../AppConfig';
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
let socket = null;

export function init(id) {
    toastr.options = {
        progressBar: true,
        preventDuplicates: true,
        positionClass: "toast-bottom-left",
        hideDuration: 300,
        timeOut: 50000
      }

    return (dispatch, getState) => {

        socket = io(`${API_SOCKET_ENDPOINT}/chat`, {
            query: {
                id: id
            }
        });
        socket.on('connect', () => { console.log('SOCKETTTTT connectsocket', socket) }
        );

        socket.on('UnseenMessagesCount', data => {
            console.log("SOCKETTTTT UpdateStatus:", data.count)
            dispatch({ type: FEACH_UPDATE_COUNT, payload: data.count });
        })

        socket.on('NewMessage', data => {
            console.log("SOCKETTTTT UpdateStatus:", data.message)
            toastr.success(data.message.message, 'رسالة جديدة', )
            // dispatch({ type: FEACH_NEW_MESSAGE, payload: data.message });
        })

    }
}




