import * as types from "../Action/type";

const initialState = {
    MessageCount: 0,
    NewMessage: null,
}

const socketReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FEACH_UPDATE_COUNT:
            console.log("Socket Reducer:", action.payload)
            return {
                ...state,
                MessageCount: action.payload,
            };

        case types.FEACH_NEW_MESSAGE:
            console.log("Socket FEACH_NEW_MESSAGE:", action.payload)
            return {
                ...state,
                NewMessage: action.payload,
            };

        default:
            return state;
    }

}

export default socketReducer;